<template>
  <div class="login position-center"
    @keydown.enter="loginKeydown"
    :style="{'backgroundImage':'url('+pageBg+')'}">
    <div class="main position-center">
      <div class="left" :style="{'backgroundImage':'url('+subBg+')'}">
        <el-image style="height:80px;"
          :src="loginLogo"></el-image>
        <h3>{{loginSubtitle}}</h3>
      </div>
      <div class="right">
        <div class="position-center">
          <div class="switch-qrcode" v-if="loginType.indexOf('qrcode')>=0 && loginWay=='account'" title="二维码登录">
            <el-image :src="switchQrcode"
              @click="appLogin"></el-image>
          </div>
          <div class="switch-qrcode" v-if="loginWay=='qrCode'" title="帐号登录">
            <el-image :src="switchDefault"
              @click="switchWay"></el-image>
          </div>
          <div class="login-container"
            v-if="loginWay==='account'">
            <el-form ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              action="/login"
              method="post">
              <input id="_isLocal_" name="_isLocal_" type="hidden" value="true">
              <input id="go" name="go" type="hidden" :value="go">
              <div class="pwdSms-tabs">
                <span v-for="item in baseWay"
                  :key="item.code"
                  :class="{'active':item.code===loginBaseWay}"
                  v-show="item.show"
                  @click="loginBaseWay=item.code">{{item.name}}</span>
              </div>
              <div class="input-container userCode"
                :class="{'active':activeInput==='userCode','loginError':loginUsrError}">
                <el-form-item prop="username">
                  <el-input placeholder="请输入帐号"
                    prefix-icon="el-icon-user"
                    v-model="ruleForm.username"
                    clearable
                    name="username"
                    @focus="inputFocus('userCode')"
                    @blur="inputBlur('userCode')"></el-input>
                </el-form-item>
              </div>
              <div class="input-container passWord"
                :class="{'active':activeInput==='passWord','loginError':loginPwdError}">
                <el-form-item prop="password">
                  <el-tooltip class="item" effect="dark" content="大写锁定已打开" placement="bottom-end"
                    v-model="capsLockShow"
                    :manual="true">
                      <el-input placeholder="请输入密码"
                        ref="passWord"
                        prefix-icon="el-icon-lock"
                        show-password
                        v-model="ruleForm.password"
                        name="password"
                        @focus="inputFocus('passWord')"
                        @blur="inputBlur('passWord')"
                        @keyup.native="capsLock"></el-input>
                  </el-tooltip>
                </el-form-item>
              </div>
              <div class="input-container verifyCode"
                :class="{'active':activeInput==='verifyCode'}"
                v-if="authVerifyCode">
                <el-form-item prop="verifycode">
                  <el-input placeholder="请输入验证码"
                    ref="verifyCode"
                    prefix-icon="el-icon-key"
                    v-model="ruleForm.verifycode"
                    clearable
                    name="iplatform_verify_code"
                    @focus="inputFocus('verifyCode')"
                    @blur="inputBlur('verifyCode')"></el-input>
                </el-form-item>
                <el-image :src="verifyCodeImg" @click="changeVerify"></el-image>
              </div>
              <div class="auxiliary-container">
                <el-checkbox v-model="rember">记住密码</el-checkbox>
                <div>
                  <a target="_blank"
                    v-if="register"
                    :href="register">注册</a>
                  <el-divider direction="vertical"
                    v-if="register && changePwd"></el-divider>
                  <a target="_blank"
                    v-if="changePwd"
                    :href="changePwd">忘记密码</a>
                </div>
              </div>
              <el-button type="primary"
                :disabled="loginDisabled"
                :loading="loginLoading"
                @click="submitForm('ruleForm')">登录</el-button>
            </el-form>
            <div class="thirdpart-container" v-if="thirdpartWay.length>0">
              <el-divider>第三方登录方式</el-divider>
              <div>
                <div v-for="item in thirdpartWay"
                  :key="item.code"
                  @click="thridLogin(item)">
                  <img v-if="item.icon"
                    :src="item.icon">
                  <i v-else class="iconfont"
                    :class="iconMap[item.code].icon"
                    :style="{'color':iconMap[item.code].color}"></i>{{item.name}}
                </div>
              </div>
            </div>
          </div>
          <div class="login-container qrcode-container"
            v-else>
            <el-image class="position-center"
              title="点击刷新"
              :src="loginQrcode"></el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="footer" v-if="conmpany_info">
      <p v-html="conmpany_info"></p>
    </div>
    <div class="footer" v-else>
      <p>E-MAIL：{{email}} 电话：{{telphone}}</p>
      <p>地址：{{address}}</p>
    </div>
  </div>
</template> 

<script>
  import { Base64 } from 'js-base64';
  export default {
    name: "login",
    components: {},
    props: {},
    data() {
      return {
        apiTitle:"/api",
        loginDisabled:true,
        loginLoading:false,
        rember:false,
        go:"",
        authError:"",
        loginUsrError:false,
        loginPwdError:false,
        authVerifyCode:false,
        verifyCodeImg:"/verifyCode",
        email:"service@yousucloud.com",
        telphone:"4009-207-208",
        address:"福建省福州市鼓楼区福州软件园F区7号楼21层",
        conmpany_info:"",
        loginLogo:require("../assets/images/login-logo.png"),
        pageBg:require("../assets/images/login-bg.png"),
        subBg:require("../assets/images/login-sub-bg.png"),
        switchDefault:require("../assets/images/login-default.png"),
        switchQrcode:require("../assets/images/login-qrcode.png"),
        loginSubtitle:"产业数字化加速专家",
        register:"",
        changePwd:"",
        loginType:"",
        loginWay:"account",
        isCapsLock:false,
        capsLockShow:false,
        firstTochar:false,
        baseWay:[{
          code:"password",
          name:"密码登录",
          show:true
        },{
          code:"message",
          name:"短信登录",
          show:false
        }],
        thirdpartWay:[],
        iconMap:{
          wechat:{icon:"icon-icon-weixin",color:"#28C445"},
          qq:{icon:"icon-icon-qq",color:"#1E78FF"}
        },
        loginBaseWay:"password",
        activeInput:"",
        rules:{
          username:[{
            validator: this.validateAcc
          }],
          password:[{
            validator: this.validatePass
          }]
        },
        loginQrcode:"",
        appQrcode:"",
        ruleForm:{
          username:"",
          password:"",
          verifycode:"",
          _isLocal_:true,
          go:""
        }
      }
    },
    created: function() {
      this.CryptoJS = require("crypto-js");
      this.loadData();
    },
    computed: {},
    watch: {
      "ruleForm":{
        handler(val){
          let username = val.username.trim(),
            password = val.password.trim(),
            verifycode = val.verifycode;
          if(this.authVerifyCode){
            if(username && password && verifycode){
              this.loginDisabled = false;
            }else{
              this.loginDisabled = true;
            }
          }else{
            if(username && password){
              this.loginDisabled = false;
            }else{
              this.loginDisabled = true;
            }
          }
        },
        deep:true,
        immediate:true
      },
      "isCapsLock":{
        handler(val){
          this.capsLockShow = val && this.activeInput=="passWord";
        }
      },
      "activeInput":{
        handler(val){
          this.capsLockShow = val=="passWord" && this.isCapsLock;
        }
      }
    },
    methods: {
      getQueryString(name) { 
        let reg = `(^|&)${name}=([^&]*)(&|$)`;
        let r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]); return null;
      },
      switchWay(){
        this.loginWay = this.loginWay === "account" ? "qrCode" : "account";
      },
      inputFocus(str){
        this.authError="";
        this.loginLoading = false;
        if(this.loginUsrError || this.loginPwdError){
          return false;
        }
        this.activeInput = str;
      },
      inputBlur(str){
        if(str === "userCode"){
          this.loginUsrError = false;
        }
        if(str === "passWord"){
          this.loginPwdError = false;
        }
        this.activeInput = "";
      },
      validateAcc(rule, value, callback){
        if(this.authError){
          callback(new Error(" "));
        }else{
          callback();
        }
      },
      validatePass(rule, value, callback){
        if(this.authError){
          callback(new Error(this.authError));
        }else{
          callback();
        }
      },
      Encrypt(key, word){//对称加密
        let CryptoJS = this.CryptoJS;
        if(key){
          var keyEnc = CryptoJS.enc.Utf8.parse(key);
          var ivEnc = CryptoJS.enc.Utf8.parse(key);
          var srcs = CryptoJS.enc.Utf8.parse(word);
          var encrypted = CryptoJS.AES.encrypt(srcs, keyEnc, {
            iv: ivEnc,
            mode: CryptoJS.mode.CBC,
            padding:CryptoJS.pad.Pkcs7
          });
          return encrypted.toString();
        }else{
          return word;
        }
      },
      Decrypt(key, text){//对称解密
        let CryptoJS = this.CryptoJS;
        if(key && text){
          var keyEnc = CryptoJS.enc.Utf8.parse(key);
          var ivEnc = CryptoJS.enc.Utf8.parse(key);
          var decrypted = CryptoJS.AES.decrypt(text, keyEnc, {
              iv: ivEnc,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7
          })
          return decrypted.toString(CryptoJS.enc.Utf8);
        }else{
          return text; 
        }
      },
      loadData(){
        var attrs = window.attrs;
        if (attrs.b_p) {
          var b_p = Base64.decode(attrs.b_p);
          try {
            b_p = JSON.parse(b_p);
          } catch (e) {
            b_p = {};
          }
          attrs = b_p;
          if (attrs.iplatform_ico) {
            let link = null;
            link = document.querySelector("link[rel*='icon']") || document.createElement("link");
            link.ref = 'image/x-icon';
            link.ref = 'shortcut';
            link.href = attrs.iplatform_ico;
          }
        }else{
          if(!attrs.ckey){
            attrs = "";
          }
        }
        //console.log(attrs);
        if(attrs){
          this.ckey = attrs.ckey ? attrs.ckey : "";
          this.enckey = attrs.enckey ? attrs.enckey : "";
          this.go = attrs.go ? attrs.go : "";
          this.ruleForm.go = attrs.go ? attrs.go : "";
          this.conmpany_info = attrs.conmpany_info ? attrs.conmpany_info : "";
          this.register = attrs.register ? attrs.register : "";
          this.changePwd = attrs.changePwd ? attrs.changePwd : "";
          this.appQrcode = attrs.appQrcode ? attrs.appQrcode : "";
          attrs.iplatform_title ? document.title = attrs.iplatform_title : null;
          attrs.loginSubtitle ? this.loginSubtitle = attrs.loginSubtitle : null;
          attrs.logon_page_logo ? this.loginLogo = attrs.logon_page_logo : null;
          attrs.logon_page_bg ? this.pageBg = attrs.logon_page_bg : null;
          attrs.logon_page_subbg ? this.subBg = attrs.logon_page_subbg : null;
          //验证码
          attrs.authVerifyCode === "none" ? null : this.authVerifyCode = true;
          //页面错误
          this.authError = attrs.authError ? attrs.authError != "null" ? attrs.authError : "" : "";
          if(this.authError){
            this.loginUsrError = true;
            this.loginPwdError = true;
            this.$nextTick(()=>{
              this.$refs.ruleForm.validateField("username");
              this.$refs.ruleForm.validateField("password");
              //this.$refs.ruleForm.validate();
            });
          }
          //登录方式
          this.loginType = attrs.loginType ? attrs.loginType : "";
          if(this.loginType){
            //基础登录方式  
            for(let i in this.baseWay){
              let item = this.baseWay[i];
              if(this.loginType.indexOf(item.code)>=0){
                item.show = true;
              }
            }
          }
          //第三方登录方式
          try{
            this.thirdpartWay = attrs.thirdpartWay ? eval(attrs.thirdpartWay) : [];
            console.log(this.thirdpartWay)
          }catch(e){
            console.log("第三方登录参数错误："+e);
          }
          this.initPage();
        }else{
          this.$message.error("页面参数错误");
        }
      },
      initPage(){
        let username = this.$cookies.get("_noc_uid_");
        if(username!=null && username!=""){
          this.ruleForm.username = username;
        }
        let password = this.$cookies.get("_noc_apd_");
        if(password!=null && password!="") {
          password = this.Decrypt(this.enckey,password);
          this.ruleForm.password = password;
          this.rember = true;
        }
      },
      submitForm(formName) {
        if(this.loginDisabled){
          return false;
        }
        this.authError = "";
        this.loginLoading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let username = this.ruleForm.username.trim(),
              password = this.ruleForm.password.trim();
            if(username != null && username != ""){
              this.$cookies.set("_noc_uid_",username);
            }
            this.ruleForm.username = this.Encrypt(this.ckey,username);
            if(password.length<21){
              this.ruleForm.password = this.Encrypt(this.enckey,password);
            }
            if(this.rember){
              if(password != null && password != ""){
                this.$cookies.set("_noc_apd_",this.Encrypt(this.enckey,password));
              }
            }else{
              this.$cookies.remove("_noc_apd_");
            }
            this.$nextTick(()=>{
              this.ruleForm.username = username;
              this.$refs[formName].$el.submit();
            });
          }
        });
      },
      loginKeydown(){
        this.submitForm("ruleForm");
      },
      changeVerify(){
        this.verifyCodeImg = '/verifyCode?t=' + new Date().getTime();
      },
      capsLock(event){
        if(this.firstTochar){
          if(event.keyCode === 20){
            this.isCapsLock = !this.isCapsLock;
            return;
          }
        }
        var e = event || window.event;
        var keyValue = e.keyCode ? e.keyCode : e.which;
        var shiftKey = e.shiftKey ? e.shiftKey : ((keyValue == 16) ? true : false);
        if(typeof(this.ruleForm.password) === "undefined"){
          return;
        }
        var strlen = this.ruleForm.password.length;
        var password = this.ruleForm.password;
        if(strlen){
          var uniCode = password.charCodeAt(strlen-1);
          if(keyValue>=65 && keyValue<=90){
            this.firstTochar = true;
            if(((uniCode>=65 && uniCode<=90) && !shiftKey) || ((uniCode>=97 && uniCode<=122) && shiftKey)){
              this.isCapsLock = true;
            }else{
              this.isCapsLock = false;
            }
          }
        }
      },
      qrCodeLogin(qrcode){
        this.loginWay = "qrCode";
        this.loginQrcode = qrcode;
      },
      appLogin(){
        this.qrCodeLogin(this.appQrcode);
      },
      thridLogin(item){
        if(item.qrcode){
          this.qrCodeLogin(item.qrcode);
        }else if(item.url){
          window.location.href = item.url;
        }else{
          this.$message.error("登录配置错误");
        }
      }
    }
  }
</script>

<style>
  .input-container.active .el-form-item .el-input__prefix{
    color:#409eff;
  }
  .el-form-item.is-error .el-input__inner{
    background-color:#FFEDED;
  }
  .el-form-item.is-error .el-input__prefix{
    color:#f56c6c;
  }
  .login .main>div.left .el-image .el-image__inner{
    width:auto;
  }
</style>
<style scoped>
  a{
    cursor:pointer;
  }
  .position-center{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
  }
  .el-button--primary{
    background-color: #1E78FF;
    border-color: #1E78FF;
    width:100%;
  }
  .el-button--primary.is-disabled{
    color: #fff;
    background-color: #a0cfff;
    border-color: #a0cfff;
  }
  .el-image{
    display:block;
  }


  .login{
    min-width:1349px;
    min-height:690px;
    font-size:14px;
    background-position:center;
    background-size:100% auto;
    background-repeat:no-repeat;
  }
  .login .main{
    z-index:2;
    width:1000px;
    height:560px;
    box-shadow: 0px 16px 32px rgba(54, 100, 170, .2);
  }
  .login .main>div{
    position:relative;
    width:50%;
    height:100%;
    float:left;
    background-color:#fff;
  }
  .login .main>div.left{
    background-position:center;
    background-size:100% auto;
    background-repeat:no-repeat;
    text-align:center;
    
  }
  .login .main>div.left .el-image{
    margin:195px auto 72px;
  }
  .login .main>div.left h3{
    color:#fff;
    font-size:30px;
    font-weight:bold;
    letter-spacing: 10px;
    line-height:42px;
    cursor: default;
  }
  .login .main>div.right>div{
    padding:16px;
  }
  .login .main>div.right .switch-qrcode{
    overflow:auto;
  }
  .login .main>div.right .switch-qrcode .el-image{
    width:72px;
    height:72px;
    float:right;
    cursor:pointer;
  }
  .login .main>div.right .switch-qrcode .el-image:hover{
    width:70px;
    height:70px;
  }
  .qrcode-container{
    position:relative;
  }
  .qrcode-container .el-image{
    bottom:100px;
    width:200px;
    height:200px;
  }
  .login-container{
    position:absolute;
    top:88px;
    left:72px;
    right:72px;
    bottom:16px;
    margin:auto;
  }
  .pwdSms-tabs{
    overflow:auto;
    margin-bottom:40px;
  }
  .pwdSms-tabs>span{
    display:block;
    float:left;
    padding:8px 0;
    border-bottom:2px solid transparent;
    font-size:18px;
    color:#666;
    width:80px;
    margin-right:8px;
    user-select:none;
    cursor:pointer;
    line-height:24px;
  }
  .pwdSms-tabs>span.active{
    border-color:#1E78FF;
    font-weight:bold;
    font-size:20px;
    color:#333;
  }
  .input-container{
    position:relative;
    margin-bottom:24px;
  }
  .input-container:before{
    display:block;
    width:32px;
    height:16px;
    line-height:16px;
    text-align:center;
    position:absolute;
    top:-10px;
    left:8px;
    background-color:#fff;
    color:#1E78FF;
    z-index:1;
    font-size:12px;
  }
  .input-container.userCode.active:before{
    content:"帐号";
  }
  .input-container.passWord.active:before{
    content:"密码";
  }
  .input-container.userCode.active.loginError:before{
    display:none;
  }
  .input-container.passWord.active.loginError:before{
    display:none;
  }
  .input-container.verifyCode{
    overflow:hidden;
  }
  .el-form-item{
    margin-bottom:0;
  }
  .input-container.verifyCode>.el-form-item{
    float:left;
    width:140px;
  }
  .input-container.verifyCode .el-image{
    float:left;
    width:140px;
    height:40px;
    margin-left:10px;
    cursor:pointer;
  }

  .auxiliary-container{
    text-align:left;
    overflow:hidden;
    margin-bottom:40px;
  }
  .auxiliary-container>div{
    float:right;
  }
  .auxiliary-container a{
    color:#65789B;
    text-decoration:none;
  }
  .auxiliary-container a:hover{
    color:#1E78FF;
  }
  .thirdpart-container{
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    height:64px;
  }
  .thirdpart-container .el-divider--horizontal{
    width:280px;
    margin:0 auto 18px;
  }
  .thirdpart-container .el-divider--horizontal .el-divider__text{
    color:#999;
    font-size:12px;
  }
  .thirdpart-container>div{
    cursor:pointer;
  }
  .thirdpart-container>div>div{
    display:inline-block;
    margin:0 8px;
    font-size:12px;
    line-height:20px;
  }
  .thirdpart-container>div>div i.iconfont{
    position: relative;
    top: 3px;
    margin-right: 4px;
    font-size:20px;
  }
  .thirdpart-container>div>div img{
    position: relative;
    top: 5px;
    margin-right: 4px;
    width:20px;
    height:20px;
  }
  .footer{
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    padding:16px;
  }
  .footer p{
    color:#ddd;
    font-size:12px;
    margin:0;
  }
</style>