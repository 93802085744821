import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import { Loading, Message, MessageBox, Dialog, Input, Checkbox, Button, Image, Divider, Icon, Select, Option, OptionGroup, Form, FormItem, Tooltip } from "element-ui"
import VueCookies from "vue-cookies"

Vue.config.productionTip = false

Vue.prototype.axios = axios

Vue.use(Dialog);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(Button);
Vue.use(Image);
Vue.use(Divider);
Vue.use(Icon);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tooltip);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.use(VueCookies);

//登录失效后跳回
if (top != self || top.location.href.indexOf('/index') > 0 || top.location.href.indexOf('/console') > 0) {
  if (top.location != self.location || top.location.href.indexOf('/index') || top.location.href.indexOf('/console')) {
    window.onbeforeunload = null;
    top.location.href = location.href;
  }
}

new Vue({
  render: h => h(App),
  methods:{
    ajaxRequest: function( url, postData) {
      return new Promise((resolve, reject) => {
        let ajaxPostData = new URLSearchParams();
        for (var p in postData) {
          ajaxPostData.append(p, postData[p])
        }
        this.axios.post(url, ajaxPostData).then(res => {
          resolve(res.data);
        }).catch(res => {
          reject(res)
        });
      });
    }
  }
}).$mount('#app')
